<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:20px">
      <!-- 설비 도면 -->
      <c-upload
        :attachInfo="attachInfo"
        :editable="editable"
        label="LBL0003332">
      </c-upload>
    </div>
  </div>
</template>
<script>
export default {
  name: 'equipment-pid',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_PID',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
    },
  }
};
</script>